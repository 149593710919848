<template>
  <div>
    <FullLoader v-if="loading"/>
    <div v-else>
      <div v-if="invitation">
        <Toast/>
        <Dialog :visible.sync="isUpdateConfirmationVisible" :modal="true">
          <template #header>
            <h3>Application has been sent</h3>
          </template>

          <div>
            {{ $t('Your application as been sent to the system administrators.') }}
          </div>
          <div>
            {{ $t('You\'ll be notified once the application will be approved.') }}
          </div>

          <template #footer>
            <Button :label="$t('Ok')" icon="pi pi-check" autofocus @click="isUpdateConfirmationVisible = false"/>
          </template>
        </Dialog>
        <FormViewer :form="sortForm" @save="saveForm()"/>
        <HistoryViewer :history="invitation.ticket.history" @save="saveHistory()"/>
      </div>
      <div v-else>
        {{ $t('Access Denied') }}
      </div>

    </div>
  </div>
</template>

<script>
import ApiService from "../service/ApiService";
import FormViewer from '../components/FormViewer.vue';
import HistoryViewer from '../components/HistoryViewer.vue';
import FullLoader from '../components/FullLoader.vue';

export default {
  name: "UnauthorizedManager",
  data() {
    return {
      api: null,
      isUpdateConfirmationVisible: false,
      loading: true,
      invitation: null,
      sortForm: {},
      tempForm: {}
    };
  },
  created() {
    this.api = new ApiService();
    this.api.getFromEp('tickets', {
      filters: JSON.stringify([{
        field: 'user',
        value: this.$auth.user.email
      }, {field: 'active', value: true}, {field: 'type', value: "platform_access_request"}])
    }).then((tickets) => {
      if (tickets.length) {
        this.invitation = tickets[0];
        this.tempForm = JSON.parse(JSON.stringify(this.invitation.ticket.form));
        Object.keys(this.tempForm)
            .sort((a, b) => {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })
            .forEach((k) => this.sortForm[k] = this.tempForm[k]);
      }
      this.invitation.ticket.history.push({
        type: "activity",
        timestamp: new Date().toISOString(),
        icon: "pi-eye",
        author: this.$auth.user.email,
        content: this.$t("Ticket viewed by {email}", {email: this.$auth.user.email})
      });
      this.saveHistory(true);
      this.loading = false;
    })
  },
  methods: {
    saveHistory(silent) {
      this.invitation.ticket.lastActivity = Date.now();
      this.api.postToEp('tickets', this.invitation).then(() => {
        if (!silent) {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Success'),
            detail: this.$t('Ticket updated successfully'),
            life: 3000
          });
          this.isUpdateConfirmationVisible = true;
        }

      })
    },
    saveForm() {
      this.invitation.ticket.form = this.sortForm;
      this.invitation.ticket.history.push(
          {
            author: this.$auth.user.email,
            type: "activity",
            icon: "pi-play",
            timestamp: new Date().toISOString(),
            content: "Ticket updated by {email}"
          }
      )
      this.saveHistory();
    }
  },
  components: {
    FormViewer,
    HistoryViewer,
    FullLoader
  },
};
</script>

<style scoped>
</style>